.popupimg {
  width: 50%;
  border-color: white;
}

.stackrow {
  padding-bottom:10px;
}


.linkbutton {
  animation: smallDown 1s;
  border-color: rgb(0, 0, 0);
  border-style: solid;
  border-width: 0px;
  width: 100px;
  border-radius: 12px;
  height: 40px;
  background-color: rgb(32, 207, 149);
}

.linkbutton:hover:enabled {
  animation: smallUp 1s;
  animation-fill-mode: forwards;
  cursor: url('/public/hand.png'), pointer;
  background-color: rgb(175, 248, 221);
}

.linkbutton:hover:disabled {
  cursor: url('/public/cursor.png'), pointer;
}

.buttonset {
  width: 450px;
}

.theCard {
  overflow: auto;
  height: 100vh;
  z-index: -1;
}

.buttonslot {
  padding-left: 30px;
  padding-right: 30px;
}

.cont {
  padding-top: 150px;
}

.padleft {
  justify-content: center;
}

.desc {
  color: white;
  font-family: cyberlight;
  padding-right: 15px;
}

.buttText {
  font-family: cyber;
  padding-top: 5px;
  font-size: 18px;
}

.nogg {
  background-color: rgb(175, 61, 61);
}

.popuptitle {
  font-size:40px;
  color: white;
  font-family: cyber;
}

.banner {
  width: 100%;
  border-radius: 20px;
  border: solid;
  border-width:2px;
  border-color: rgb(32, 207, 149);;
  display: inline-block;
}

.logo {
  width: 40px;
  height: 40px;
  padding: 5px;
}

.builtusing {
  color: white;
  font-family: cyberlight;
}

.backbutton:hover {
  background-color: rgb(175, 248, 221);
}

.backbutton {
  background-color: rgb(32, 207, 149);
  border: none;
  border-radius: 25px;
  text-align: center;
  text-decoration: none;
  position: relative;
  animation-fill-mode: forwards;
  width: 28px;
  height: 28px;
  border-image-slice: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  
}

.bb {
  position: absolute;
  transform: translate(-20px, -20px);
  background-color: black;
  width: 60px;
  height: 60px;
  border: 2px solid rgb(32, 207, 149);
}

.bb:hover {
  background-color: rgb(175, 248, 221);
  border: none;
}

.bbtext {
  padding-bottom: 10px;
  color: rgb(32, 207, 149);
  font-weight: 700;
}

@keyframes jumpUp {
  from {
    transform: translate(0%, 100%);
  }
  to {
    transform: translate(0px, 0px);
  }
}

@keyframes jumpDown {
  from {
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(0%, 100%);
  }
}

.popupcol {
  position: relative;
  pointer-events: all;
  animation: jumpUp 1s;
  border-top: solid;
  border-left: solid;
  border-right: solid;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-width: 2px;
  border-color: rgb(32, 207, 149);
  background-color: rgb(0, 25, 46);
  background-image: linear-gradient(315deg, rgb(6, 6, 6) 0%, rgb(14, 14, 14) 74%);
  padding: 40px;
}

.popupcolend {
  pointer-events: all;
  animation: jumpDown 1s;
  border-top: solid;
  border-left: solid;
  border-right: solid;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-width: 2px;
  border-color: rgb(32, 207, 149);
  background-color: rgb(0, 25, 46);
  background-image: linear-gradient(315deg, rgb(6, 6, 6) 0%, rgb(14, 14, 14) 74%);
  padding: 40px;
}

.buttonText {
  color: white;
  font-weight: 600;
  font-family: cyberlight;
  padding-left: 10px;
}

.icontxt {
  padding-left: 10px;
}