.bigtext {
  font-size: 40px;
}

.errorLink {
  padding-top: 30px;
  position: absolute;
}

.divvy {
  background-image: linear-gradient(90deg, #43DF9B, rgb(5, 141, 121));
  padding: 50px;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
}

.notfound {
  color: white;
  padding-top: 400px;
}

.message {
  width: 80%;
  font-family: cyber;
}

.fouroh {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: rgb(166, 241, 200);
  animation: glitch 1s infinite;
}

.fouroh span:first-child {
  position: absolute;
  animation: glitch 650ms infinite;
  z-index: 1;
  clip-path: polygon(0 0, 100% 0, 100% 40%, 0 40%);
  transform: translate(-0.025em, -0.0125em);
}

.fouroh span:last-child {
  position: absolute;
  animation: glitch 350ms infinite;
  z-index: 1;
  clip-path: polygon(0 60%, 100% 60%, 100% 100%, 0 100%);
  transform: translate(0.025em, 0.0125em);
}

@keyframes glitch {
  0% {
    text-shadow: 0.05em 0 0 rgba(0, 0, 0, 0.75),
      -0.025em -0.05em 0 rgba(0, 79, 0, 0.75),
      0.05em 0.025em 0 rgba(0, 0, 79, 0.75);
  }
  14% {
    text-shadow: 0.05em 0 0 rgba(0, 0, 0, 0.75),
      -0.025em -0.05em 0 rgba(0, 79, 0, 0.75),
      0.05em 0.025em 0 rgba(0, 0, 79, 0.75);
  }
  15% {
    text-shadow: -0.05em 0 0 rgba(0, 0, 0, 0.75),
      0.025em -0.05em 0 rgba(0, 79, 0, 0.75),
      0.05em -0.025em 0 rgba(0, 0, 79, 0.75);
  }
  49% {
    text-shadow: -0.05em 0 0 rgba(0, 0, 0, 0.75),
      0.025em -0.05em 0 rgba(0, 79, 0, 0.75),
      0.05em -0.025em 0 rgba(0, 0, 79, 0.75);
  }
  50% {
    text-shadow: 0.05em 0 0 rgba(0, 0, 0, 0.75),
      0.025em -0.05em 0 rgba(0, 79, 0, 0.75),
      -0.05em 0.025em 0 rgba(0, 0, 79, 0.75);
  }
  99% {
    text-shadow: 0.05em 0 0 rgba(0, 0, 0, 0.75),
      0.025em -0.05em 0 rgba(0, 79, 0, 0.75),
      -0.05em 0.025em 0 rgba(0, 0, 79, 0.75);
  }
  100% {
    text-shadow: 0.05em 0 0 rgba(0, 0, 0, 0.75),
      -0.025em 0.025em 0 rgba(0, 79, 0, 0.75),
      -0.05em 0.025em 0 rgba(0, 0, 79, 0.75);
  }
}
