.backstuff {
  justify-content: center;
  z-index: 0;
}

.headingtext {
  font-family: cyberlight;
}

.buttonrow {
  width: 500px;
}

.centeredtext {
  display: flex;
  align-items: center;
  justify-content: center;
}

.proj {
  font-size: 70px;
  color:rgb(26, 180, 103);
}

.paddingbig {
  padding-top: 200px;
  padding-bottom: 20px;
}

.set {
  height: 22vw;
}

.pads {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
}

.top-buffer { margin-top:20px;}

.projecttext span:first-child {
  position: absolute;
  animation: glitch 650ms infinite;
  z-index: 1;
  clip-path: polygon(0 0, 100% 0, 100% 40%, 0 40%);
  transform: translate(-0.025em, -0.0125em);
}

.projecttext span:last-child {
  position: absolute;
  animation: glitch 350ms infinite;
  z-index: 1;
  clip-path: polygon(0 60%, 100% 60%, 100% 100%, 0 100%);
  transform: translate(0.025em, 0.0125em);
}

.bar {
  position: absolute;
}

.justsomestuff {
  position: relative;
  color: rgb(255, 255, 255);
  font-size: 30px;
}

.center {
  text-align: center;
}

.appBarRow {
  align-items: center;
  animation: stretch 2s;
  background-image: linear-gradient(90deg, #43DF9B, rgb(5, 141, 121));
  animation: fadeIn 1s;
}

@keyframes glitch {
  0% {
    text-shadow: 0.05em 0 0 rgba(255, 0, 0, 0.75),
      -0.025em -0.05em 0 rgba(0, 255, 0, 0.75),
      0.05em 0.025em 0 rgba(0, 0, 255, 0.75);
  }
  14% {
    text-shadow: 0.05em 0 0 rgba(255, 0, 0, 0.75),
      -0.025em -0.05em 0 rgba(0, 255, 0, 0.75),
      0.05em 0.025em 0 rgba(0, 0, 255, 0.75);
  }
  15% {
    text-shadow: -0.05em 0 0 rgba(255, 0, 0, 0.75),
      0.025em -0.05em 0 rgba(0, 255, 0, 0.75),
      0.05em -0.025em 0 rgba(0, 0, 255, 0.75);
  }
  49% {
    text-shadow: -0.05em 0 0 rgba(255, 0, 0, 0.75),
      0.025em -0.05em 0 rgba(0, 255, 0, 0.75),
      0.05em -0.025em 0 rgba(0, 0, 255, 0.75);
  }
  50% {
    text-shadow: 0.05em 0 0 rgba(255, 0, 0, 0.75),
      0.025em -0.05em 0 rgba(0, 255, 0, 0.75),
      -0.05em 0.025em 0 rgba(0, 0, 255, 0.75);
  }
  99% {
    text-shadow: 0.05em 0 0 rgba(255, 0, 0, 0.75),
      0.025em -0.05em 0 rgba(0, 255, 0, 0.75),
      -0.05em 0.025em 0 rgba(0, 0, 255, 0.75);
  }
  100% {
    text-shadow: 0.05em 0 0 rgba(255, 0, 0, 0.75),
      -0.025em 0.025em 0 rgba(0, 255, 0, 0.75),
      -0.05em 0.025em 0 rgba(0, 0, 255, 0.75);
  }
}

.rowOfSelectable {
  justify-content: left;
}

.middlesec {
  width: 900px;
  justify-content: center;
  align-items: center;
}
.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.parent {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.projecttext {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: rgb(255, 255, 255);
  font-size: 90px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  animation: glitch 1s infinite;
}

.appBar {
  position: fixed;
  width:100%;
  border-bottom: 3px solid black;
  z-index: 1;
}

.paddingTop {
  padding-top: 30px;
  padding-bottom: 10px;
}

.card {
  background-color: #79797900;
  border-radius: 0px;
  padding-bottom: 20px;
}

.appbar {
  width: 400px;
}

.container {
  padding-bottom: 20px;
}
