.big {
  width: 20%;
}

.icon {
  width: 100%;
}

.word {
  font-size: 20px;
  position: absolute;
  width: 100%;
  font-family: cyber;
  padding-top: 20px;
}

.pinktext {
  color: black;
  padding-bottom: 10px;
}

.pinktext:hover {
  color: black;
}

.bluebux {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  text-align: center;
  text-decoration: none;
  position: relative;
  animation-fill-mode: forwards;
  width: 100px;
  height: 40px;
  border-image-slice: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: inherit;
}

.helpbux {
  display: none;
  color:#21212100;
  background-color: #21212100;
  border: none;
  text-align: center;
  border-width: 2px;
  text-decoration: none;
  position: relative;
  animation-fill-mode: forwards;
  width: 100px;
  height: 25px;
  padding: none;
  border-image-slice: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: inherit;
}

.bluebux:hover {
  cursor: url('/public/hand.png'), pointer;
  animation: up 0.25s;
  background-color: rgb(175, 248, 221);
  color: rgb(175, 248, 221);
  animation-fill-mode: forwards;
}

.helpbux:hover {
  cursor: url('/public/hand.png'), pointer;
  background-color: rgb(175, 248, 221);
  animation: up 0.25s;
  animation-fill-mode: forwards;
}

.txt {
  color: white;
  font-size: 10px;
  position: relative;
  width: 150px;
}

.bigbux:hover {
  background-color: rgb(175, 248, 221);
  animation-fill-mode: forwards;
  mix-blend-mode: multiply;
}

@keyframes up {
  from {
  }
  to {
    border: solid;
    border-width: 2px;
  }
}

@keyframes down {
  from {
    border-bottom: solid;
    color: rgb(0, 0, 0);
  }
  to {
  }
}

