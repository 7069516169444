.title {
  color: white;
  font-size: 3vh;
  font-family: "Gill Sans", "Gill Sans MT", "Calibri", "Trebuchet MS",
    "sans-serif";
  position: absolute;
  padding: 87% 0px 0px 10px;
  text-shadow: 10px;
}

.description {
  font-size: 2vh;
  color: white;
  font-family: "Gill Sans", "Gill Sans MT", "Calibri", "Trebuchet MS",
    "sans-serif";
}

.buttons {
  position: absolute;
  margin: auto;
}


.imgbox:hover {
  animation: expand 0.25s;
  animation-fill-mode: forwards;
  cursor: url('/public/hand.png'), pointer;
}

.backgrounddiv {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.873);
  position: fixed;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: fadeIn 0.75s;
}

.popupBackground {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

@keyframes contract {
  from {
    border-color: rgb(32, 207, 149);
    border-width: 2px;
    border-radius: 20px;
  }
  to {
  }
}

@keyframes expand {
  from {
  }
  to {
    border-color: rgb(32, 207, 149);
    border-width: 2px;
    border-radius: 20px;
  }
}

.genres {
  z-index: 1;
  position: absolute;
  width: 40px;
}

.code:hover {
  background-color: rgb(0, 0, 0);
}

.imgbox {
  z-index:2;
  border-color: white;
  object-fit: cover;
  border: solid;
  border-width: 10px;
  cursor: pointer;
  animation: contract 1s;
  animation-fill-mode: forwards;
  border-color: rgb(92, 92, 92);
  border-width: 0px;
  overflow: hidden;
}

.imgg {
  width: 100%;
  height: 100%;
}

.buttontext {
  color: white;
  font-family: "Gill Sans", "Gill Sans MT", "Calibri", "Trebuchet MS",
    "sans-serif";
}

.blurrytext {
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-align: center;
}

.subtitle {
  overflow: hidden;
  padding-top: 15px;
  padding-left:20px;
  padding-right:20px;
  height: 90px;
}

.project {
  animation: fadeIn 1s;
  border-radius: 20px;
  background-image: linear-gradient(315deg, rgb(5, 5, 5) 0%, rgb(24, 24, 24) 74%);
  overflow: hidden;
}
