@font-face {
  font-family: cyberlight;
  src: url(Components/futurette/Louis\ George\ Cafe\ Light.ttf);
}

@font-face {
  font-family: cyber;
  src: url(Components/futurette/Louis\ George\ Cafe.ttf);
}

.ripples {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 30%);
}

.homebuttonrow {
  width:100vw;
  align-items: center;
  animation: stretch 2s;
  background-image: linear-gradient(90deg, #43DF9B, rgb(5, 141, 121));
}

@keyframes stretch {
  from {
    width:500px;
  }

  to {
    width:100vw;
  }
}

.ring {
  background:  linear-gradient(90deg, #43DF9B, rgb(5, 141, 121));
}

.backgroundlogo {
  width: 150px;
  height:150px;
}

html {
  cursor: url('/public/cursor.png'), auto;
}

.App {
  text-align: center;
  animation: fadeIn 2s;
}

.bigdiv {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
}

.titletext {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: rgb(255, 255, 255);
  font-size: 90px;
  font-family: cyber;
  animation: glitch 1s infinite;
}

.particles {
  position: absolute;
  width: 100vw;
  height: 100vh;
}

.titletext span {
  position: absolute;
  width: 100vw;
}

.padding {
  padding-bottom: 30px;
}


.paddingLeftRow {
  width: 100px;
  height: 60px;
}

.quote {
  font-style: italic;
}

.aboutme {
  font-family: cyberlight;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 30px;
  padding-bottom: 30px;
  color: white;
  animation: fadeIn 1s;
}

.paddingtop {
  padding-top: 60px;
}

.titletext span:first-child {
  animation: glitch 650ms infinite;
  clip-path: polygon(0 0, 100% 0, 100% 40%, 0 40%);
  transform: translate(-0.025em, -0.0125em);
}

.titletext span:last-child {
  animation: glitch 350ms infinite;
  clip-path: polygon(0 60%, 100% 60%, 100% 100%, 0 100%);
  transform: translate(0.025em, 0.0125em);
}

@keyframes glitch {
  0% {
    text-shadow: 0.05em 0 0 rgba(255, 0, 0, 0.75),
      -0.025em -0.05em 0 rgba(0, 255, 0, 0.75),
      0.05em 0.025em 0 rgba(0, 0, 255, 0.75);
  }
  14% {
    text-shadow: 0.05em 0 0 rgba(255, 0, 0, 0.75),
      -0.025em -0.05em 0 rgba(0, 255, 0, 0.75),
      0.05em 0.025em 0 rgba(0, 0, 255, 0.75);
  }
  15% {
    text-shadow: -0.05em 0 0 rgba(255, 0, 0, 0.75),
      0.025em -0.05em 0 rgba(0, 255, 0, 0.75),
      0.05em -0.025em 0 rgba(0, 0, 255, 0.75);
  }
  49% {
    text-shadow: -0.05em 0 0 rgba(255, 0, 0, 0.75),
      0.025em -0.05em 0 rgba(0, 255, 0, 0.75),
      0.05em -0.025em 0 rgba(0, 0, 255, 0.75);
  }
  50% {
    text-shadow: 0.05em 0 0 rgba(255, 0, 0, 0.75),
      0.025em -0.05em 0 rgba(0, 255, 0, 0.75),
      -0.05em 0.025em 0 rgba(0, 0, 255, 0.75);
  }
  99% {
    text-shadow: 0.05em 0 0 rgba(255, 0, 0, 0.75),
      0.025em -0.05em 0 rgba(0, 255, 0, 0.75),
      -0.05em 0.025em 0 rgba(0, 0, 255, 0.75);
  }
  100% {
    text-shadow: 0.05em 0 0 rgba(255, 0, 0, 0.75),
      -0.025em 0.025em 0 rgba(0, 255, 0, 0.75),
      -0.05em 0.025em 0 rgba(0, 0, 255, 0.75);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.inputField {
  border-color:rgb(16, 131, 73);
  background-color: #00002100;
  height: 40px;
  width: 300px;
  border: 2px solid rgb(0, 0, 0);
  color: black;
  font-size: 25px;
  font-family: cyber;
  border-radius: 10px;
  padding: 10px;
}

.bottomButtonPadding {
  width: 300px;
}

.inputField:focus {
  outline: none
}

.smallicon {
  width: 32px;
  height: 32px;
}

.whitetext {
  font-family: cyberlight;
  font-size: 50px;
  height: 65px;
  color: rgb(255, 255, 255);
  background: rgb(0, 0, 0);
  mix-blend-mode:multiply;
  width: 100%;
}

.textF {
  width: 100%;
  background-color: #000021;
  border-color: white;
  border-width: 2px;
}
